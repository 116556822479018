section.header_block {
	width: 100%;
	min-height: 798px;
	background-position: center center;
	background-size: cover;
	padding-top: 40px;
	box-sizing: border-box;
	position: relative;
}

.header_logo_position {
	display: flex;
	align-items: center;
	position: relative;
}

.button_link {
	margin-left: 53px;
	span,
	a {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #ffffff;
		box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
		border-radius: 72px;
		width: 240px;
		height: 55px;
		color: #922358;
		text-align: center;
		font-weight: 600;
		font-size: 20px;
		line-height: 145%;
		cursor: pointer;
	}
}

.button_link2 {
	position: absolute;
	top: 95px;
	left: 0;
	margin: 0;
	span,
	a {
		width: auto;
		padding: 0 40px
	}
}

.header_block_line {
	height: 240px;
	background: #6d3ea8;
	width: 100%;
	position: absolute;
	bottom: 85px;
}

.header_position_content {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 240px;
}

.header_product_title {
	h1 {
		font-weight: bold;
		font-size: 60px;
		line-height: 100%;
		color: #ffffff;
	}

	span {
		font-weight: 600;
		font-size: 46px;
		line-height: 100%;
		color: #ffffff;
		display: block;
		margin-top: 11px;
	}
}

.header_product_description {
	font-size: 24px;
	line-height: 155%;
	text-transform: uppercase;
	color: #ffffff;
	margin-top: 11px;
}

.header_product {
	position: relative;

	img {
		position: absolute;
		left: 0;
		bottom: -100px;
	}
}

.header_product,
.header_product_title,
.header_product_description {
	width: calc(100% / 3 - 20px);
}

@media only screen and (max-width: 1260px) {
	.header_product_description {
		font-size: 22px;
	}
}

@media only screen and (max-width: 1140px) {
	.header_product_title h1 {
		font-size: 50px;
	}

	.header_product_title span {
		font-size: 35px;
	}
}

@media only screen and (max-width: 1024px) {
	.header_product {
		width: 35%;
	}

	.header_product_title,
	.header_product_description {
		width: calc(65% / 2 - 10px);
	}

	.header_product_description {
		font-size: 18px;
	}

	section.header_block {
		background-position: 64% top;
	}
}

@media only screen and (max-width: 900px) {
	.header_position_content {
		flex-direction: column;
		align-items: flex-start;
	}
}
