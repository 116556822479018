.modal_position{
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, .7);
	position: fixed;
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease-in-out;
	&.active{
		opacity: 1;
		visibility: visible;
		transition: all 0.3s ease-in-out;
	}
}

.modal_block{
	max-width: 1024px;
	width: 100%;
	padding: 30px;
	box-sizing: border-box;
	background-color: #ffffff;
	opacity: 0;
	visibility: hidden;
	position: relative;
	z-index: 10;
	&.active{
		opacity: 1;
		visibility: visible;
		transition: all 0.5s ease-in-out;
	}
	@media only screen and (max-width: 768px) {
		padding: 10px;
	}
}

.modal_block_text {
	margin-right: -20px;
	padding-right: 20px;
	overflow-y: auto;
	max-height: 560px;
	@media only screen and (max-width: 768px) {
		margin-right: -10px;
		padding-right: 10px;
		margin-left: -10px;
		padding-left: 10px;
		max-height: calc(100vh - 50vh);
		padding-bottom: 1px;
	}
	ul {
		list-style: disc;
		margin: 16px 0;
		padding-left: 40px;
	}
}

.close_modal{
	position: absolute;
	right: 30px;
	z-index: 10;
	background-color: #ffffff;
	padding: 10px;
	box-sizing: border-box;
	@media only screen and (max-width: 768px) {
		right: 0;
	}
}

.modal_overlay{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.overflow_hidden {
	overflow: hidden;
}