section.instruction_block{
	margin-top: 50px;
	color: #939393;
	font-size: 14px;
	line-height: 145%;
	h3{
		font-style: italic;
		font-weight: bold;
		font-size: 20px;
		line-height: 145%;
	}
}

.instruction_block_line{
	margin-top: 40px;
	margin-bottom: 50px;
	border-bottom: 2px solid rgba(0, 0, 0, 0.15);
}

.instruction_block_top, .instruction_block_bottom{
	max-width: 930px;
	width: 100%;
}

.instruction_block_bottom ul, .instruction_block_bottom ol{
	padding: 0;
	margin-left: 16px;
	li{
		line-height: 145%;
		color: #939393;
	}
}

.instruction_line{
	padding: 80px 0;
	text-align: center;
	background-position: center center;
	background-size: cover;
	h3 {
		font-weight: 600;
		font-size: 46px;
		line-height: 100%;
		text-align: center;
		color: #FFFFFF;
	}
	.instruction_download_link{
		margin-top: 35px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: -30px;
		a{
			display: block;
			max-width: 370px;
			width: 100%;
			background: #FFFFFF;
			box-shadow: 0  4px 18px rgba(0, 0, 0, 0.12);
			border-radius: 72px;
			font-weight: 600;
			font-size: 20px;
			line-height: 145%;
			text-align: center;
			color: #922358;
			padding: 15px 5px;
			margin: 0 10px 30px 10px;
		}
	}
}

@media only screen and (max-width: 500px) {

	.instruction_line{
		padding: 40px 0;
		h3{
			font-size: 25px;
		}
		.instruction_download_link{
			a{
				padding: 15px 10px;
				font-size: 16px;
			}
		}
	}

}
