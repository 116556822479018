section.research_block{
	background-size: cover;
	background-position: center center;
	padding-bottom: 90px;
	background-attachment: fixed;
}

.research_block_list{
	margin-top: 80px;
}

.research_line{
	width: 100%;
	background: #6D3EA8;
	h3 {
		font-weight: 600;
		font-size: 60px;
		line-height: 120%;
		text-align: center;
		text-transform: uppercase;
		color: #FFFFFF;
		padding: 50px 0;
	}
}

@media only screen and (max-width: 1024px) {

	.research_line{
		h3{
			font-size: 40px;
		}
	}

}

@media only screen and (max-width: 600px) {

	.research_line{
		h3{
			font-size: 35px;
			padding: 30px 0;
		}
	}

	section.research_block{
		padding-bottom: 40px;
	}

	.research_block_list{
		margin-top: 40px;
	}

}

@media only screen and (max-width: 400px) {
	.research_block {
		background-image: url("https://www.l-thyroxin.ru/admin/wp-content/uploads/2020/06/bg4-mobile.jpg") !important;
	}

	.research_line{
		h3{
			font-size: 17px;
		}
	}

}

