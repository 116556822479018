.research_item{
	background: #FFFFFF;
	padding: 35px 160px 35px 70px;
	box-sizing: border-box;
	margin-bottom: 30px;
	cursor: pointer;
	border: 5px solid #FFFFFF;
	transition: border 0.5s ease-in-out;
	position: relative;
	.title{
		font-weight: 600;
		font-size: 26px;
		line-height: 145%;
		color: #272727;
	}
	.author{
		font-weight: 600;
		font-size: 20px;
		line-height: 145%;
		color: #939393;
		margin-top: 15px;
	}
	.content{
		font-size: 20px;
		line-height: 145%;
		color: #272727;
		margin-top: 30px;
		display: none;
	}
}

.research_item:before{
	position: absolute;
	right: 74px;
	top: 80px;
	content: url("./pic/icon.svg");
}

.research_item.active{
	border: 5px solid #7E48C3;
	transition: border 0.5s ease-in-out;
	.content{
		display: inline-block;
	}
}

.research_item.active:before{
	transform: rotate(180deg);
}

.research_item:last-child{
	margin-bottom: 0;
}

@media only screen and (max-width: 768px) {

	.composition_item .title{
		font-size: 30px;
	}

	.research_item .title{
		font-size: 20px;
	}

	.research_item .author{
		font-size: 16px;
	}

	.research_item .content{
		font-size: 18px;
	}

	.composition_item .composition_item_position{
		margin-top: 20px;
	}

}

@media only screen and (max-width: 600px) {

	.research_item{
		padding: 35px 50px 35px 50px;
		.content{
			font-size: 16px;
		}
	}

	.research_item:before{
		top: 20px;
		right: 20px;
		content: url("./pic/icon-small.svg");
	}

}

@media only screen and (max-width: 400px) {

	.research_item{
		padding: 35px 20px 20px 20px;
		.title{
			font-size: 17px;
		}
		.content {
			font-size: 15px;
		};
	}

}
