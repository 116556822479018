.composition_item{
	background: #FFFFFF;
	padding: 64px 110px;
	box-sizing: border-box;
	margin-bottom: 30px;
	.title{
		font-weight: 600;
		font-size: 40px;
		line-height: 125%;
		text-align: center;
		color: #272727;
	}
	.composition_item_position{
		display: flex;
		justify-content: space-between;
		margin-top: 50px;
	}
	.composition_item_left, .composition_item_right{
		width: calc(50% - 30px);
		font-size: 20px;
		line-height: 145%;
		color: #272727;
		sup{
			font-size: 12px;
		}
	}
	.composition_item_center {
		width: calc(50% - 30px);
		font-size: 20px;
		line-height: 145%;
		color: #272727;
		sup{
			font-size: 12px;
		}
	}
}

.composition_item_center.center,
.composition_item_left.center{
	width: 100%;
	text-align: center;
}

.composition_item:last-child{
	margin-bottom: 0;
}

@media only screen and (min-width: 1001px) {

	.composition_item_wrap .composition_item_position {
		flex-wrap: wrap;
	}

}

@media only screen and (max-width: 1000px) {

	.composition_item .composition_item_position {
		flex-direction: column;
		.composition_item_left, .composition_item_right {
			width: 100%;
		}
	}

	.composition_item {
		padding: 64px 80px;
	}

}

@media only screen and (max-width: 600px) {

	.composition_item{
		padding: 64px 50px;
	}

}

@media only screen and (max-width: 400px) {

	.composition_item{
		padding: 40px 20px;
		.title{
			font-size: 19px !important;
			sup{
				font-size: 11px !important;
			}
		}
		p{
			font-size: 15px;
			line-height: 1.3;
		}
	}

}
