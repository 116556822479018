footer{
	width: 100%;
	padding-top: 65px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.med_footer{
	width: 100%;
	margin-top: 50px;
	padding-bottom: 73px;
}

.footer_block_logo{
	display: inline-flex;
	flex-direction: column;
	font-weight: 600;
	font-size: 18px;
	line-height: 145%;
	color: #272727;
	padding-right: 100px;
	cursor: pointer;
	img{
		margin-top: 20px;
	}
}

.footer_block_content{
	font-size: 18px;
	line-height: 145%;
	color: #272727;
	a {
		color: #272727;
		text-decoration: underline;
	}
}

@media only screen and (max-width: 600px) {

	footer{
		flex-direction: column;
		.footer_block_logo{
			padding-right: 0;
			width: 80%;
		}
		.footer_block_content{
			padding-top: 40px;
			font-size: 14px;
		}
	}

}
