section.composition {
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
}

@media only screen and (max-width: 400px) {
	.composition {
		&_one {
			background-image: url("https://www.l-thyroxin.ru/admin/wp-content/uploads/2020/06/bg1-scaled-mobile.jpg") !important;
		}
		&_two {
			background-image: url("https://www.l-thyroxin.ru/admin/wp-content/uploads/2020/06/bg2-mobile.jpg") !important;
		}
		&_three {
			background-image: url("https://www.l-thyroxin.ru/admin/wp-content/uploads/2020/06/bg3-mobile.jpg") !important;
		}
	}
}

.mob-adapt{
	display: none;
}

.composition_line {
	width: 100%;

	h2, h3, h4 {
		font-weight: 600;
		font-size: 60px;
		line-height: 120%;
		text-align: center;
		text-transform: uppercase;
		color: #FFFFFF;
		padding: 50px 0;
	}
}

@media only screen and (max-width: 1024px) {

	.composition_line h2, .composition_line h3, .composition_line h4{
		font-size: 40px;
	}

	.mob-adapt{
		display: block;
	}

	.desc-adapt{
		display: none;
	}

}

@media only screen and (max-width: 768px) {

	.composition_line h2, .composition_line h3, .composition_line h4{
		font-size: 35px;
	}

}

@media only screen and (max-width: 600px) {

	.composition_line h2, .composition_line h3, .composition_line h4{
		padding: 30px 0;
	}

}

@media only screen and (max-width: 400px) {

	.composition_line h2, .composition_line h3, .composition_line h4{
		font-size: 17px;
	}

	section.composition{
		padding: 0 0 40px 0 !important;
	}

}
