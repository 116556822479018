section.header_mobile {
	min-height: 100%;
	height: 100%;

	.header_mobile_top {
		height: 50%;
		width: 100%;
		background-position: center center;
		background-size: cover;
		padding: 30px 20px;
		box-sizing: border-box;
	}

	.header_mobile_bottom {
		min-height: 50%;
		height: 100%;
		background: #6d3ea8;
		padding: 0 20px;
		box-sizing: border-box;
		color: #ffffff;

		.header_mobile_product {
			position: relative;
			top: -150px;

			img {
				max-width: 60%;
			}
		}

		.header_mobile_product_title {
			margin-top: -130px;
			padding: 0 30px;

			h1 {
				font-weight: bold;
				font-size: 60px;
				line-height: 100%;
			}

			span {
				font-weight: 600;
				font-size: 46px;
				line-height: 100%;
				margin-top: 11px;
				display: block;
			}
		}

		.header_mobile_product_description {
			font-size: 24px;
			line-height: 155%;
			text-transform: uppercase;
			margin-top: 40px;
			padding: 0 30px;
		}
	}
}

.header_logo_position_mob {
	display: flex;
	align-items: center;
	position: relative;
	@media only screen and (max-width: 600px) {
		flex-direction: column;
		align-items: flex-start;
	}
}

.button_link_mob {
	margin-left: 30px;
	@media only screen and (max-width: 600px) {
		margin-left: 0;
		margin-top: 18px;
	}
	span,
	a {
		font-weight: 600;
		font-size: 12px;
		line-height: 145%;
		text-align: center;
		color: #922358;
		width: 140px;
		height: 33px;
		background: #ffffff;
		box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
		border-radius: 48.3px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
}

.button_link_mob2 {
	margin-left: 0;
	position: absolute;
	top: 53px;
	left: 0;
	@media only screen and (max-width: 600px) {
		margin-left: 0;
		margin-top: 18px;
		position: inherit;
		top: auto;
	}
	span,
	a {
		width: auto;
		padding: 0 30px;
	}
}

@media only screen and (max-width: 600px) {
	section.header_mobile {
		padding-bottom: 80px;

		.header_mobile_top {
			min-height: 55px;
		}

		.header_mobile_bottom {
			min-height: 45%;

			.header_mobile_product {
				position: relative;
				top: -100px;

				img {
					max-width: 260px;
				}
			}

			.header_mobile_product_title {
				margin-top: -80px;
				padding: 0 15px;

				h1 {
					font-size: 54px;
					line-height: 100%;
				}

				span {
					font-weight: 600;
					font-size: 36px;
					margin-top: 9px;
					display: block;
				}
			}

			.header_mobile_product_description {
				font-size: 19px;
				line-height: 155%;
				padding: 0 15px;
			}
		}
	}
}
