section.about_block {
	width: 100%;
	background: #6D3EA8;
	padding: 90px 0;

	h2 {
		font-weight: 600;
		font-size: 60px;
		line-height: 120%;
		text-align: center;
		text-transform: uppercase;
		color: #FFFFFF;
	}
}

.about_video_player_button{
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;
	justify-items: center;
	&:before{
		content: url("pic/play-icon.svg");
		position: absolute;
		z-index: 10;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transform-origin: 0 0;
		background-color: rgba(100%, 100%, 100%, .8);
		border-radius: 50%;
		width: 70px;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 6px;
		box-sizing: border-box;
		line-height: 0;
	}
}

.about_position {
	display: flex;
	justify-content: space-between;
	margin-top: 100px;

	.about_position_block {
		width: calc(50% - 30px);

		.video-react {
			padding-top: 53.57% !important;
		}
	}

	.title {
		font-weight: 600;
		font-size: 40px;
		line-height: 125%;
		color: #FFFFFF;
	}

	.content {
		font-size: 20px;
		line-height: 145%;
		text-transform: uppercase;
		color: #FFFFFF;
		margin-top: 30px;
	}

	.content_icon {
		margin-top: 35px;
	}
}

.about_position_block {
	position: relative;

	button.video-react-big-play-button {
		position: absolute;
		background: #FFFFFF !important;
		width: 125px !important;
		border: none;
		height: 85px !important;
		display: flex;
		padding: 0;
		line-height: 0;
		justify-content: center;
		align-items: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 0;
	}

	span.video-react-control-text {
		display: none;
	}
}

.about_position_block .video-react-big-play-button:before {
	content: url("./pic/play-icon.svg");
	display: flex;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: 1000px) {

	.about_position {
		flex-direction: column;

		.about_position_block {
			width: 100%;
			text-align: center;
		}

		.about_position_block:last-child {
			margin-top: 40px;
		}
	}

}

@media only screen and (max-width: 768px) {

	section.about_block h2 {
		font-size: 45px;
	}

	.about_position {
		margin-top: 40px;
	}

}


@media only screen and (max-width: 600px) {

	section.about_block h2 {
		font-size: 35px;
	}

	.about_position_block {
		.title {
			font-size: 20px;
		}

		.content {
			font-size: 16px;
		}
	}

	section.about_block {
		padding: 40px 0;
	}

}

@media only screen and (max-width: 400px) {

	section.about_block h2 {
		font-size: 24px;
	}

	.about_position_block {
		.content {
			font-size: 15px;
		}
	}

	.about_position_block button.video-react-big-play-button {
		width: 100px !important;
		height: 65px !important;
	}

}
