label.custom_checkbox{
	max-width: 407px;
	width: 100%;
	display: block;
	position: relative;
	input{
		display: none;
	}
	span{
		display: block;
		font-size: 16px;
		line-height: 145%;
		color: #272727;
		padding-left: 60px;
	}
	span:before{
		content: '';
		border: 2px solid #000000;
		box-sizing: border-box;
		width: 34px;
		height: 34px;
		line-height: 0;
		position: absolute;
		left: 0;
		top: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

label.custom_checkbox input:checked + span:before{
	content: url("./pic/check-icon.svg");
}

@media only screen and (max-width: 500px) {

	label.custom_checkbox {
		span {
			font-size: 12px;
			padding-left: 30px;
		}
		span:before{
			width: 20px;
			height: 20px;
		}
	}

}
