.message_box {
	max-width: 920px;
	width: calc(100% - 40px);
	padding: 70px 100px;
	box-sizing: border-box;
	background: linear-gradient(0deg, rgba(109, 62, 168, 0.15), rgba(109, 62, 168, 0.15)), #ffffff;
	position: fixed;
	z-index: 200;
	top: 50%;
	left: 50%;
	transform: scale(0) translate(-50%, -50%);
	transform-origin: 0 0;
	transition: all 0.3s ease-in-out;
	visibility: hidden;
	opacity: 0;
	max-height: 98%;
	overflow-y: auto;
	.title {
		font-weight: 600;
		font-size: 40px;
		line-height: 125%;
		color: #272727;
	}
	.content {
		font-size: 20px;
		line-height: 145%;
		color: #272727;
	}
	.buttons {
		display: flex;
		margin-top: 50px;
		flex-wrap: wrap;
		justify-content: center;
		button {
			border-radius: 70px;
			height: 70px;
			padding: 20px 80px;
			box-sizing: border-box;
			font-size: 20px;
			line-height: 0;
			font-weight: bold;
			text-align: center;
			color: #922358;
			text-transform: uppercase;
			margin-bottom: 20px;
		}
		button:first-child {
			margin-right: 30px;
		}
	}
}

button.white_button {
	background: #ffffff;
	transition: all 0.3s ease-in-out;
}

button.white_button:disabled {
	opacity: 0.5;
	transition: all 0.3s ease-in-out;
}

button.border_button {
	border: 2px solid #922358;
}

.message_box.active {
	transform: scale(1) translate(-50%, -50%);
	transition: all 0.3s ease-in-out;
	opacity: 1;
	visibility: visible;
}

.message_box_overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease-in-out;
}

.message_box_overlay.active {
	opacity: 1;
	visibility: visible;
	transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 600px) {
	.message_box {
		padding: 40px 50px;
		.title {
			font-size: 30px;
		}
		.buttons button {
			width: 100%;
			margin-right: 0 !important;
		}
		.content {
			font-size: 16px;
			line-height: 1.6;
		}
	}
}

@media only screen and (max-width: 500px) {
	.message_box {
		padding: 20px;
		.title {
			font-size: 18px;
		}
		.content {
			font-size: 12px;
		}
	}

	.message_box .buttons button {
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-size: 12px;
	}

	.message_box .buttons {
		margin-top: 30px;
	}
}
