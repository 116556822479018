@import "Fonts";
@import "~video-react/styles/scss/video-react";

html, body{
	padding: 0;
	margin: 0;
	line-height: normal;
	min-height: 100%;
	width: 100%;
	height: 100%;
	font-family: 'Proxima Nova', sans-serif;
}

#root{
	min-height: 100%;
	width: 100%;
	height: 100%;
}

*{
	outline: none;
}

a{
	text-decoration: none;
}

h1, h2, h3, h4{
	margin: 0;
}

button{
	font-family: 'Proxima Nova', sans-serif;
	background-color: transparent;
	padding: 0;
	border: none;
	text-align: center;
	cursor: pointer;
}

img{
	max-width: 100%;
	height: auto;
}

ul{
	list-style: none;
	margin: 0;
	padding: 0;
}

span.link {
	color: #272727;
	text-decoration: underline;
	cursor: pointer;
}